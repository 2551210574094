import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  Modal,
  Tabs,
  Tab,
} from '@mui/material';
import {
  doc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import useMediaQuery from '@mui/material/useMediaQuery';

ChartJS.register(ArcElement, Tooltip, Legend);

function Home() {
  const { currentUser, userRole, userStatus, loading } = useAuth();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [bedsOccupied, setBedsOccupied] = useState(0);
  const [upcomingEmptyBeds, setUpcomingEmptyBeds] = useState(0);
  const [emptyBeds, setEmptyBeds] = useState(0);

  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [openModal, setOpenModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  // Flags to ensure we only fetch once
  const [usersFetched, setUsersFetched] = useState(false);

  // Wait for loading to complete before rendering
  if (loading) {
    return null; // Or you can return a loading spinner here
  }

  // Redirect unauthenticated users to login page
  useEffect(() => {
    if (!currentUser && !loading) {
      navigate('/login');
    }
  }, [currentUser, navigate, loading]);

  // Redirect users with 'user' role to '/licensee-services'
  useEffect(() => {
    if (userRole === 'user' && !loading) {
      navigate('/licensee-services');
    }
  }, [userRole, navigate, loading]);

  // Fetch and calculate bed data (occupied, upcoming empty, empty)
  useEffect(() => {
    if ((userRole === 'admin' || userRole === 'viewings') && !loading) {
      const fetchBedData = async () => {
        try {
          console.log('Fetching bed data...');
          const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
          const noticesSnapshot = await getDocs(collection(db, 'notices'));

          // We will use the REFERENCE CODE instead of "licenseeId" to match notices with licensees.
          // Key: referenceCode, Value: array of noticeDate(s)
          const noticesByReference = {}; // <-- CHANGED

          noticesSnapshot.forEach((noticeDoc) => {
            const noticeData = noticeDoc.data();
            const refCode = noticeData.referenceCode; // <-- CHANGED
            const dateRaw = noticeData.noticeDate;     // <-- CHANGED

            if (!refCode) return; // skip if no referenceCode in doc

            // Convert to JS Date in case it's a Firestore Timestamp
            let dateObj = null;
            if (dateRaw && typeof dateRaw.toDate === 'function') {
              dateObj = dateRaw.toDate();
            } else {
              dateObj = new Date(dateRaw || 0);
            }

            if (!noticesByReference[refCode]) {
              noticesByReference[refCode] = [];
            }
            noticesByReference[refCode].push(dateObj);
          });

          let occupiedCount = 0;
          let upcomingEmptyCount = 0;
          let emptyCount = 0;
          const today = new Date();
          console.log('Today is:', today);

          licenseesSnapshot.forEach((licDoc) => {
            const licData = licDoc.data();
            const licName = licData.NAME;
            const refCode = licData['REFERENCE CODE']; // <-- CHANGED
            const noticeDates = refCode
              ? noticesByReference[refCode] || []
              : [];

            console.log(
              'Licensee:',
              refCode,
              'Name:',
              licName,
              'Notice dates:',
              noticeDates
            );

            if (licName === 'EMPTY') {
              // Automatically empty bed
              emptyCount++;
            } else {
              if (noticeDates.length > 0) {
                // Sort notice dates and use the earliest one
                const earliestNoticeDate = noticeDates.sort((a, b) => a - b)[0];

                if (earliestNoticeDate > today) {
                  // Future move-out date => upcoming empty
                  upcomingEmptyCount++;
                } else {
                  // Notice date is in the past => effectively empty now
                  emptyCount++;
                }
              } else {
                // No notices => occupant is still there
                occupiedCount++;
              }
            }
          });

          console.log(
            'Occupied:',
            occupiedCount,
            'Upcoming Empty:',
            upcomingEmptyCount,
            'Empty:',
            emptyCount
          );
          setBedsOccupied(occupiedCount);
          setUpcomingEmptyBeds(upcomingEmptyCount);
          setEmptyBeds(emptyCount);
        } catch (error) {
          console.error('Error fetching bed data:', error);
        }
      };
      fetchBedData();
    }
  }, [userRole, loading]);

  // Fetch users data from Firestore (once)
  useEffect(() => {
    if ((userRole === 'admin' || userRole === 'viewings') && !loading && !usersFetched) {
      const fetchUsers = async () => {
        try {
          console.log('Fetching users...');
          const usersRef = collection(db, 'users');

          // Fetch pending users
          const pendingQuery = query(usersRef, where('status', '==', 'pending'));
          const pendingSnapshot = await getDocs(pendingQuery);
          const pendingUsersList = pendingSnapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
          console.log('Pending users:', pendingUsersList);

          // Fetch approved users
          const approvedQuery = query(
            usersRef,
            where('status', '==', 'approved'),
            orderBy('emailVerified', 'desc')
          );
          const approvedSnapshot = await getDocs(approvedQuery);
          const approvedUsersList = approvedSnapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
          console.log('Approved users:', approvedUsersList);

          // Fetch all users
          const allUsersSnapshot = await getDocs(usersRef);
          const allUsersList = allUsersSnapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
          console.log('All users:', allUsersList);

          setPendingUsers(pendingUsersList);
          setApprovedUsers(approvedUsersList);
          setAllUsers(allUsersList);
          setUsersFetched(true);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };

      fetchUsers();
    }
  }, [userRole, loading, usersFetched]);

  const approveUser = async (userId) => {
    try {
      console.log('Approving user:', userId);
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: 'approved' });
      setPendingUsers((prev) => prev.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const denyUser = async (userId) => {
    try {
      console.log('Denying user:', userId);
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: 'denied' });
      setPendingUsers((prev) => prev.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Error denying user:', error);
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Updated pie data with three categories:
  const bedData = {
    labels: ['Beds Occupied', 'Upcoming Empty Beds', 'Empty Beds'],
    datasets: [
      {
        data: [bedsOccupied, upcomingEmptyBeds, emptyBeds],
        backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
      },
    ],
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const now = new Date();
  const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  // Recently Approved: users with status 'approved' and Created_At within the last week
  const recentlyApprovedUsers = approvedUsers.filter((user) => {
    if (!user.Created_At) return false;
    const userCreationDate =
      typeof user.Created_At?.toDate === 'function'
        ? user.Created_At.toDate()
        : new Date(user.Created_At || 0);
    return userCreationDate >= oneWeekAgo;
  });

  // Pending: already filtered by status in Firestore query
  const pendingToDisplay = pendingUsers;

  // All Registered: sort by creation time ascending (using Created_At)
  const allRegisteredSorted = [...allUsers].sort((a, b) => {
    const aDate =
      typeof a.Created_At?.toDate === 'function'
        ? a.Created_At.toDate()
        : new Date(a.Created_At || 0);
    const bDate =
      typeof b.Created_At?.toDate === 'function'
        ? b.Created_At.toDate()
        : new Date(b.Created_At || 0);
    return aDate - bDate;
  });

  // Helper to format Created_At in UI
  const formatCreationDate = (createdAt) => {
    const dateObj =
      typeof createdAt?.toDate === 'function'
        ? createdAt.toDate()
        : new Date(createdAt || 0);
    return dateObj.toLocaleDateString();
  };

  // If user status is pending, show pending approval message
  if (userStatus === 'pending') {
    console.log('User status is pending, showing pending message.');
    return (
      <Container>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4">Your Account is Pending Approval</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please wait for an administrator to approve your account.
          </Typography>
        </Paper>
      </Container>
    );
  }

  // If user role is not 'admin' or 'viewings', render nothing
  if (userRole !== 'admin' && userRole !== 'viewings') {
    console.log('User role is neither admin nor viewings, returning null.');
    return null;
  }

  console.log('Rendering main component.');

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {/* First Pie Chart (Beds) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box sx={{ width: { xs: '90%', md: '50%' } }}>
            <Pie data={bedData} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{ p: { xs: 2, md: 3 }, textAlign: 'center' }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              centered
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Recently Approved" />
              <Tab label="Pending" />
              <Tab label="All Registered" />
            </Tabs>

            {tabIndex === 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">
                  Recently Approved Licensees
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {recentlyApprovedUsers.map((user) => (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Paper
                        elevation={2}
                        sx={{ p: 2, backgroundColor: '#d4edda' }}
                      >
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>
                          Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                        </Typography>
                        <Typography>
                          Reference Code: {user.referenceCode}
                        </Typography>
                        <Typography>
                          Created:{' '}
                          {user.Created_At
                            ? formatCreationDate(user.Created_At)
                            : 'NA'}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {tabIndex === 1 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">
                  Pending Users for Manual Approval
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {pendingToDisplay.map((user) => (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Paper
                        elevation={2}
                        sx={{ p: 2, backgroundColor: '#fff3cd' }}
                      >
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>
                          Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                        </Typography>
                        <Typography>
                          Created:{' '}
                          {user.Created_At
                            ? formatCreationDate(user.Created_At)
                            : 'NA'}
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ fontSize: '0.875rem', mr: 2 }}
                            onClick={() => approveUser(user.id)}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ fontSize: '0.875rem' }}
                            onClick={() => denyUser(user.id)}
                          >
                            Deny
                          </Button>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {tabIndex === 2 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">All Registered Users</Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {allRegisteredSorted.map((user) => (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>
                          Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                        </Typography>
                        <Typography>Status: {user.status}</Typography>
                        {user.referenceCode && (
                          <Typography>
                            Reference Code: {user.referenceCode}
                          </Typography>
                        )}
                        <Typography>
                          Created:{' '}
                          {user.Created_At
                            ? formatCreationDate(user.Created_At)
                            : 'NA'}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Modal for viewing pending user list on mobile */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="pending-users-modal-title"
        aria-describedby="pending-users-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            id="pending-users-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            Pending Users for Approval
          </Typography>
          <Grid container spacing={2}>
            {pendingUsers.map((user) => (
              <Grid item xs={12} key={user.id}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    backgroundColor: '#fff3cd',
                  }}
                >
                  <Typography variant="h6">{user.fullName}</Typography>
                  <Typography>Email: {user.email}</Typography>
                  <Typography>
                    Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                  </Typography>
                  <Typography>
                    Created:{' '}
                    {user.Created_At
                      ? formatCreationDate(user.Created_At)
                      : 'NA'}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ fontSize: '0.875rem', mr: 2 }}
                      onClick={() => approveUser(user.id)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ fontSize: '0.875rem' }}
                      onClick={() => denyUser(user.id)}
                    >
                      Deny
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default Home;
