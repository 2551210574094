import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { formatDate } from '../utils/utils';
import { Input } from '../tailwind/catalyst/input';
import { Field, FieldGroup, Label } from '../tailwind/catalyst/fieldset';
import { Button } from '../tailwind/catalyst/button';
import LicenseeForm from './LicenseeForm'; // <-- Make sure this path is correct for your setup

function MovingOutList() {
  const [notices, setNotices] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredNotices, setFilteredNotices] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showForm, setShowForm] = useState(false);
  const [selectedReferenceCode, setSelectedReferenceCode] = useState('');
  const [preSelectedMoveInDate, setPreSelectedMoveInDate] = useState('');
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [licensees, setLicensees] = useState([]);

  // Fetch notices
  useEffect(() => {
    console.log('Fetching notices from Firestore...');
    const fetchNotices = async () => {
      const noticesSnapshot = await getDocs(collection(db, 'notices'));
      const noticesList = noticesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log('Notices fetched:', noticesList);
      setNotices(noticesList);
    };
    fetchNotices();
  }, []);

  // Fetch licensees to determine if someone has moved in for a reference code
  useEffect(() => {
    const fetchLicensees = async () => {
      const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
      const licenseesList = licenseesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLicensees(licenseesList);
    };
    fetchLicensees();
  }, []);

  // Window resize
  useEffect(() => {
    const handleResize = () => {
      const mobileStatus = window.innerWidth <= 768;
      console.log('Window resized. Is mobile view:', mobileStatus);
      setIsMobile(mobileStatus);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter & sort notices
  useEffect(() => {
    // Filter by search
    const filteredList = notices.filter(
      (notice) =>
        notice.name.toLowerCase().includes(search.toLowerCase()) ||
        notice.referenceCode.toLowerCase().includes(search.toLowerCase()) ||
        notice.reason.toLowerCase().includes(search.toLowerCase())
    );

    // Sort by noticeDate
    filteredList.sort((a, b) => {
      const dateA = new Date(a.noticeDate);
      const dateB = new Date(b.noticeDate);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    console.log('Filtered notices:', filteredList);
    setFilteredNotices(filteredList);
  }, [search, notices, sortOrder]);

  const toggleSortOrder = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    console.log('Sort order changed:', newOrder);
    setSortOrder(newOrder);
  };

  const handleOpenForm = (referenceCode, moveOutDate) => {
    console.log('Opening form with referenceCode:', referenceCode, 'and moveOutDate:', moveOutDate);
    setSelectedReferenceCode(referenceCode || '');
    setPreSelectedMoveInDate(moveOutDate);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    console.log('Closing form');
    setShowForm(false);
  };

  const handleViewNotice = (notice) => {
    console.log('Viewing notice details:', notice);
    setSelectedNotice(notice);
  };

  const handleCloseView = () => {
    console.log('Closing notice details view');
    setSelectedNotice(null);
  };

  // Delete notice
  const handleDeleteNotice = async (id) => {
    try {
      await deleteDoc(doc(db, 'notices', id));
      console.log(`Deleted notice with id: ${id}`);
      setNotices((prevNotices) => prevNotices.filter((notice) => notice.id !== id));
    } catch (error) {
      console.error('Error deleting notice:', error);
    }
  };

  // Separate upcoming and past notices
  const upcomingNotices = filteredNotices.filter(
    (notice) => new Date(notice.noticeDate).getTime() > Date.now()
  );
  const pastNotices = filteredNotices.filter(
    (notice) => new Date(notice.noticeDate).getTime() <= Date.now()
  );

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Moving Out List</h1>

      <FieldGroup>
        <Field>
          <Label>Search</Label>
          <Input
            value={search}
            onChange={(e) => {
              console.log('Search input changed:', e.target.value);
              setSearch(e.target.value);
            }}
            placeholder="Search by name or reference code"
          />
        </Field>
        <Button onClick={toggleSortOrder}>
          Sort by Move-Out Date ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
        </Button>
      </FieldGroup>

      {/* UPCOMING NOTICES */}
      {isMobile ? (
        <div className="mt-6">
          <h2 className="text-2xl font-semibold mb-4">Upcoming Empty Beds (Mobile View)</h2>
          {upcomingNotices.map((notice) => (
            <div key={notice.id} className="bg-white shadow-lg rounded-lg p-4 mb-4">
              <h3 className="text-lg font-bold">Reference Code: {notice.referenceCode}</h3>
              <div className="flex justify-between mt-4">
                <Button onClick={() => handleViewNotice(notice)}>View</Button>
                <Button onClick={() => handleOpenForm(notice.referenceCode, notice.noticeDate)}>
                  Fill Bed
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-6">
          <h2 className="text-2xl font-semibold mb-4">Upcoming Empty Beds</h2>
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">Reference Code</th>
                <th className="border border-gray-300 px-4 py-2">Move-Out Date</th>
                <th className="border border-gray-300 px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {upcomingNotices.map((notice) => (
                <tr key={notice.id}>
                  <td className="border border-gray-300 px-4 py-2">{notice.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{notice.referenceCode}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    {formatDate(notice.noticeDate)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <Button onClick={() => handleOpenForm(notice.referenceCode, notice.noticeDate)}>
                      Fill Bed
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* PAST NOTICES */}
      <div className="mt-10">
        <h2 className="text-2xl font-semibold mb-4">Past Notices</h2>
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">Reference Code</th>
              <th className="border border-gray-300 px-4 py-2">Move-Out Date</th>
              <th className="border border-gray-300 px-4 py-2">Current Occupant</th>
              <th className="border border-gray-300 px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {pastNotices.map((notice) => {
              // Find occupant in 'licensees' collection that has the same REFERENCE CODE
              const occupant = licensees.find(
                (lic) => lic['REFERENCE CODE'] === notice.referenceCode
              );
              // Format occupant's name
              const occupantName = occupant
                ? `${occupant.NAME || ''}${occupant.SURNAME ? ' ' + occupant.SURNAME : ''}`
                : 'No occupant found';

              return (
                <tr key={notice.id}>
                  <td className="border border-gray-300 px-4 py-2">{notice.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{notice.referenceCode}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    {formatDate(notice.noticeDate)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {occupantName.trim() !== '' ? occupantName : 'No occupant found'}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <Button variant="danger" onClick={() => handleDeleteNotice(notice.id)}>
                      Delete Notice
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* FORM MODAL */}
      {showForm && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          {/* Add a max height and overflow for scrollability */}
          <div className="bg-white rounded-lg p-6 w-full max-w-lg relative max-h-[80vh] overflow-y-auto">
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
              onClick={handleCloseForm}
            >
              ×
            </button>
            <LicenseeForm
              preSelectedReferenceCode={selectedReferenceCode}
              preSelectedMoveInDate={preSelectedMoveInDate}
            />
          </div>
        </div>
      )}

      {/* NOTICE DETAILS VIEW MODAL */}
      {selectedNotice && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg relative max-h-[80vh] overflow-y-auto">
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
              onClick={handleCloseView}
            >
              ×
            </button>
            <h2 className="text-xl font-semibold mb-4">Notice Details</h2>
            <p>
              <strong>Name:</strong> {selectedNotice.name}
            </p>
            <p>
              <strong>Reference Code:</strong> {selectedNotice.referenceCode}
            </p>
            <p>
              <strong>Move-Out Date:</strong> {formatDate(selectedNotice.noticeDate)}
            </p>
            <p>
              <strong>Reason:</strong> {selectedNotice.reason}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default MovingOutList;
